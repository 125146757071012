import GuestLayout from '@/components/Layouts/GuestLayout'
import AuthTwitchButton from '@/components/AuthTwitchButton'
import VibelAppName from '@/components/VibelAppName'

const Login = () => {
    return (
        <GuestLayout>
            <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-white">
                <VibelAppName route={'/'} />

                <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                    <AuthTwitchButton />
                </div>
            </div>
        </GuestLayout>
    )
}

export default Login
