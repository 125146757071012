import ButtonLink from '@/components/ButtonLink'
import IconTwitch from '@/components/icons/IconTwitch'
import routes from '../../constants/routes'
import ValidationErrors from '@/components/ValidationErrors'

const AuthTwitchButton = () => {
	const buttonText = 'Login with Twitch'
	const errors = []

	return (
		<>
			<ValidationErrors className="mb-4" errors={errors} />

			<ButtonLink
				className={'w-full flex justify-center items-center'}
				url={process.env.NEXT_PUBLIC_BACKEND_URL + routes.AUTH_TWITCH_REDIRECT()}
				name={( <><IconTwitch className={'h-6 w-6 mr-3'}/>{buttonText}</> )}/>
		</>
	)
}

export default AuthTwitchButton